
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'IconComponent',
  props: {
    name: {
      type: String as PropType<string>,
      default: null,
    },
  },
  computed: {
    // allows us to change the svg fill color
    iconColor(): string {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'icon-white';
      }

      const urlOrigin = new URL(window.location.origin).href;

      // determine color for white labeling
      if (urlOrigin.includes('ringcentral')) {
        return 'icon-ringcentral';
      }

      // default color of #005eb8 (primary)
      return '';
    },
  },
});
